import HttpClient, { Response } from "./http-client";
import CreditPolicy, { SpecialCreditPolicyRecord } from "@/types/credit-policy";
import Segment from "@/types/segment";

export default class CreditPolicyService extends HttpClient {
  private static classInstance?: CreditPolicyService;

  private constructor() {
    super(process.env.VUE_APP_CREDIT_ENGINE_URL!);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new CreditPolicyService();
    }

    return this.classInstance;
  }

  public async listCreditPolicies(
    filters?: ListCreditPoliciesParams
  ): Promise<Response<CreditPolicy[]>> {
    return this.axiosCall<CreditPolicy[]>({
      method: "GET",
      url: `/credit-policies/list`,
      params: filters
    });
  }
  public async listSegments(): Promise<Response<Segment[]>> {
    return this.axiosCall<Segment[]>({
      method: "GET",
      url: `/segments/list`
    });
  }

  public async createCreditPolicy(
    data: CreateCreditPolicyParams
  ): Promise<Response<CreditPolicy>> {
    return this.axiosCall<CreditPolicy>({
      method: "POST",
      url: `/credit-policies/create`,
      data: data
    });
  }

  public async updateCreditPolicy(
    creditPolicyId: number,
    data: UpdateCreditPolicyParams
  ): Promise<Response<CreditPolicy>> {
    return this.axiosCall<CreditPolicy>({
      method: "PATCH",
      url: `/credit-policies/update/${creditPolicyId}`,
      data: data
    });
  }

  public async removeCreditPolicy(
    creditPolicyId: number
  ): Promise<Response<CreditPolicy>> {
    return this.axiosCall<CreditPolicy>({
      method: "DELETE",
      url: `/credit-policies/delete/${creditPolicyId}`
    });
  }

  public async getCreditPolicy(
    creditPolicyId: number,
    options?: {
      loadSpecialCreditPolicyRecords?: boolean;
      loadEventHistory?: boolean;
    }
  ): Promise<Response<CreditPolicy>> {
    return this.axiosCall<CreditPolicy>({
      method: "GET",
      url: `/credit-policies/get/${creditPolicyId}`,
      params: options
    });
  }
}

export interface ListCreditPoliciesParams {
  typeId?: number;
  segmentName?: string;
}

export interface CreateCreditPolicyParams {
  typeId: number;
  segmentName?: string | null;
  engineId?: number | null;
  identifier: string;
  description: string;
  specialCreditPolicyRecords?: Omit<
    SpecialCreditPolicyRecord,
    "id" | "creditPolicyId"
  >[];
}

export interface UpdateCreditPolicyParams {
  statusId: number;
  identifier: string;
  description: string;
  engineId?: number | null;
  specialCreditPolicyRecords?: Omit<
    SpecialCreditPolicyRecord,
    "id" | "creditPolicyId"
  >[];
}
