import Segment from "./segment";

export default interface CreditPolicy {
  id: number;
  typeId: number;
  type: CreditPolicyType;
  identifier: string;
  description: string;
  segmentId?: number | null;
  segment?: Segment | null;
  engineId?: number;
  engine?: CreditPolicyEngine | null;
  statusId: number | null;
  status: CreditPolicyStatus | null;
  specialCreditPolicyRecords?: SpecialCreditPolicyRecord[] | null;
  created_at: Date;
  updated_at: Date;
}

interface CreditPolicyType {
  id: number;
  name: string;
  description: string;
}

const typeBySegment: CreditPolicyType = {
  id: 1,
  name: "BY_SEGMENT",
  description: "Por segmento"
};

const typeSalaryAnticipation: CreditPolicyType = {
  id: 2,
  name: "SPECIAL",
  description: "Especial"
};

export const CreditPolicyTypeEnum = {
  BY_SEGMENT: typeBySegment,
  SPECIAL: typeSalaryAnticipation
};

interface CreditPolicyStatus {
  id: number;
  name: string;
  description: string;
}

const statusActive: CreditPolicyStatus = {
  id: 1,
  name: "ACTIVE",
  description: "Ativa"
};

const statusInactive: CreditPolicyStatus = {
  id: 2,
  name: "INACTIVE",
  description: "Inativa"
};

export const CreditPolicyStatusEnum = {
  ACTIVE: statusActive,
  INACTIVE: statusInactive
};

interface CreditPolicyEngine {
  id: number;
  name: string;
  description: string;
}

const engineDefault: CreditPolicyEngine = {
  id: 1,
  name: "DEFAULT",
  description: "Motor padrão"
};

const engineByInterval: CreditPolicyEngine = {
  id: 2,
  name: "BY_INTERVAL",
  description: "Motor novo"
};

export const CreditPolicyEngineEnum = {
  DEFAULT: engineDefault,
  BY_INTERVAL: engineByInterval
};

export interface SpecialCreditPolicyRecord {
  id: number;
  creditPolicyId: number;
  minIncome: number;
  maxIncome: number;
  timesIncome: number;
  minTime: number;
  maxTime: number;
  feePercentage: number;
  numInstallments: number;
  minNumInstallments: number;
  withRestriction: boolean;
  product: SpecialCreditPolicyRecordProduct;
}

export interface SpecialCreditPolicyRecordProduct {
  id: number;
  name: string;
  description: string;
}

const privatePayrollLoan: SpecialCreditPolicyRecordProduct = {
  id: 1,
  name: "CONSIGNADO_PRIVADO",
  description: "Consignado Privado"
};

const publicPayrollLoan: SpecialCreditPolicyRecordProduct = {
  id: 2,
  name: "CONSIGNADO_PUBLICO",
  description: "Consignado Público"
};

const privateAnticipationLoan: SpecialCreditPolicyRecordProduct = {
  id: 3,
  name: "ANTECIPACAO_PRIVADO",
  description: "Antecipação Privado"
};

const publicAnticipationLoan: SpecialCreditPolicyRecordProduct = {
  id: 4,
  name: "ANTECIPACAO_PUBLICO",
  description: "Antecipação Público"
};

const privateDebtPurchaseLoan: SpecialCreditPolicyRecordProduct = {
  id: 5,
  name: "COMPRA_DIVIDA_PRIVADO",
  description: "Compra Dívida Privado"
};

const publicDebtPurchaseLoan: SpecialCreditPolicyRecordProduct = {
  id: 6,
  name: "COMPRA_DIVIDA_PUBLICO",
  description: "Compra Dívida Público"
};

const privateRefinLoan: SpecialCreditPolicyRecordProduct = {
  id: 7,
  name: "REFIN_PRIVADO",
  description: "Refin. Privado"
};

const publicRefinLoan: SpecialCreditPolicyRecordProduct = {
  id: 8,
  name: "REFIN_PUBLICO",
  description: "Refin. Público"
};

export const SpecialCreditPolicyRecordProductEnum = {
  PRIVATE_PAYROLL_LOAN: privatePayrollLoan,
  PUBLIC_PAYROLL_LOAN: publicPayrollLoan,
  PRIVATE_ANTICIPATION_LOAN: privateAnticipationLoan,
  PUBLIC_ANTICIPATION_LOAN: publicAnticipationLoan,
  PRIVATE_DEBT_PURCHASE_LOAN: privateDebtPurchaseLoan,
  PUBLIC_DEBT_PURCHASE_LOAN: publicDebtPurchaseLoan,
  PRIVATE_REFIN_LOAN: privateRefinLoan,
  PUBLIC_REFIN_LOAN: publicRefinLoan
};
