var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Page',{attrs:{"title":"Tomadores"},scopedSlots:_vm._u([{key:"filtersActions1",fn:function(){return [(_vm.addBorrower)?_c('BorrowerManageModal',{on:{"close":_vm.closeModal,"input":_vm.fetchBorrowers}}):_vm._e()]},proxy:true},{key:"card1",fn:function(){return [_c('div',{staticClass:"mb-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('SelectCompany',{attrs:{"label":"Empresa Vinculada","on-item-selected":(company) => {
                _vm.filters.companyId = company?.id || null;
              },"autocomplete-props":{
              clearable: true,
              outlined: true,
              hideDetails: 'auto'
            }}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Nome Tomador","outlined":"","hide-details":"","clearable":""},model:{value:(_vm.filters.name),callback:function ($$v) {_vm.$set(_vm.filters, "name", $$v)},expression:"filters.name"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##']),expression:"['###.###.###-##']"}],attrs:{"append-icon":"mdi-magnify","label":"CPF Tomador","outlined":"","hide-details":"","clearable":""},model:{value:(_vm.filters.cpf),callback:function ($$v) {_vm.$set(_vm.filters, "cpf", $$v)},expression:"filters.cpf"}})],1),(_vm.borrowerStatusList)?_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-autocomplete',{attrs:{"hide-details":"auto","clearable":"","items":_vm.borrowerStatusList,"item-text":"name","item-value":"id","outlined":"","label":"Status"},model:{value:(_vm.filters.statusId),callback:function ($$v) {_vm.$set(_vm.filters, "statusId", $$v)},expression:"filters.statusId"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-btn',{attrs:{"large":"","color":"primary"},on:{"click":function($event){return _vm.applyFilters()}}},[_vm._v(" Filtrar ")])],1)],1)],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.borrowers.data,"server-items-length":_vm.borrowers.total,"options":_vm.options,"footer-props":{
        itemsPerPageOptions: [10, 25, 50, 100]
      },"loading-text":"Carregando...","locale":"pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [(_vm.editBorrower && _vm.editBorrower.id === item.id)?_c('BorrowerManageModal',{attrs:{"borrower":_vm.editBorrower},on:{"input":_vm.fetchBorrowers,"close":_vm.closeModal,"policy-update-success":_vm.fetchBorrowers}}):_vm._e(),_c('v-btn',{staticClass:"my-1",attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}},{key:`item.relatedCompanies`,fn:function({ item }){return _vm._l((item?.companies),function(company){return _c('v-chip',{key:company.id,staticClass:"ma-1",attrs:{"small":""}},[_c('small',[_vm._v(_vm._s(company.name)+" ")])])})}},{key:`item.createdAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:`item.borrower`,fn:function({ item }){return [_vm._v(" "+_vm._s(item?.name || "Cadastrando")+" "),_c('br'),_c('small',[_vm._v(_vm._s(item.cpf ? _vm.formatCPF(item.cpf) : "Cadastrando"))])]}},{key:`item.status`,fn:function({ item }){return [_c('v-chip',{attrs:{"color":_vm.statusColors(item.statusId)}},[_vm._v(_vm._s(_vm.formatStatus(item.statusId)))])]}}],null,true)}),(_vm.borrowers.data && _vm.borrowers.data.length)?_c('div',{staticClass:"pt-6"},[_c('v-btn',{attrs:{"block":"","large":"","color":"primary"},on:{"click":_vm.downloadXls}},[(_vm.loadingXls)?_c('span',[_c('v-progress-circular',{staticClass:"mr-3",attrs:{"size":"18","indeterminate":"","left":""}}),_vm._v(" Gerando arquivo... ")],1):_c('span',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Baixar planilha excel ")],1)])],1):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }